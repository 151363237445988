import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Section = styled.section`
  width: 100%;
  background: var(--offWhite);
`

export const ContainerStacked = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1128px;
  margin: 0 auto;
  padding: var(--xl) var(--md);

  ${media.tablet`
    grid-template-columns: 1fr 2fr;
  `}

  h3 {
    text-transform: uppercase;
    letter-spacing: 1.4px;
    color: var(--primary);
    margin-bottom: var(--md);
    font-size: var(--copyNormal);
  }

  ul {
  }
`
export const ContainerSplit = styled.div`
  // display: grid;
  // grid-template-columns: 1fr;
  max-width: 1128px;
  margin: 0 auto;
  padding: var(--xl) var(--md);

  h3 {
    text-align: center;
    font-size: var(--titleLevel1);
    margin: 0 0 var(--lg);
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: left;

    ${media.tablet`
        align-items: center;
    `}
  }

  ul {
    display: grid;
    grid-template-columns: 1fr;

    ${media.tablet`
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 var(--lg);
  `}
  }
`

export const ListItem = styled.li`
  margin-bottom: var(--lg);
  padding-top: var(--xs);

  ${media.tablet`
    display: grid;
    grid-template-columns: 1fr 3fr;
  `}

  h3 {
    text-transform: uppercase;
    letter-spacing: 1.4px;
    color: var(--primary);
    font-size: var(--copyNormal);
    margin-bottom: var(--xs);
    text-align: left;
  }

  span {
    color: var(--primary);
    font-family: var(--headings);
    font-size: var(--titleLevel4);
    line-height: var(--titleLevel4);
    display: block;
    position: relative;
    margin-bottom: var(--xs);

    ${media.tablet`
      margin-right: var(--md);
    `}

    ::after {
      content: '';
      width: 100%;
      height: 1px;
      background: var(--primary);
      display: inline-block;
      position: absolute;
      left: 0;
      top: 18px;
    }
  }
`
