import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Heading = styled.h3`
  text-align: center;
  font-size: var(--titleLevel1);
  margin-bottom: var(--lg);
`
export const Slide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
export const Img = styled.img`
  max-height: 48px;
  width: 100%;

  ${media.tablet`
    height: 72px;
    max-height: 72px;
    `}
`
export const AwardContainer = styled.div`
  display: flex;
  justify-content: center;

  div {
    margin: 0 16px;

    ${media.tablet`
      margin: 0 32px;
    `}
  }
`
