import React from 'react'
import PropTypes from 'prop-types'
import CopyBlock from 'components/copyBlock'
import { Heading, Slide, Img, AwardContainer } from './awards.css'

import threebestrated from 'images/awards/threebestrated.svg'
import nextgen from 'images/awards/nextgen.svg'

const Awards = ({ bgTint, borderTop = true, topMargin }) => (
  <CopyBlock bgTint={bgTint} borderTop={borderTop} topMargin={topMargin}>
    <Heading>Our Awards</Heading>
    <AwardContainer>
      <Slide>
        <a
          href="https://threebestrated.co.uk/it-services-in-watford"
          target="_blank"
          rel="noopener noreferrer"
          title="Three Best Rated"
        >
          <Img src={threebestrated} alt="Three Best Rated" />
        </a>
      </Slide>
      <Slide>
        <a
          href="https://www.channelfutures.com/"
          target="_blank"
          rel="noopener noreferrer"
          title="Channel Futures NextGen 101"
        >
          <Img src={nextgen} alt="Channel Futures NextGen 101" />
        </a>
      </Slide>
    </AwardContainer>
  </CopyBlock>
)

Awards.propTypes = {
  bgTint: PropTypes.bool,
  borderTop: PropTypes.bool,
  topMargin: PropTypes.bool
}

export default Awards
