import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #eee;
  height: 284px;
  margin-bottom: var(--xl);
  position: relative;

  ${media.desktop`
    // margin-bottom: var(--xl);
  `}
`
export const Container = styled.div`
  width: 100%;
  max-width: 1128px;
  padding: var(--lg) var(--md);
  color: var(--white);
  z-index: 2;

  h1 {
    font-size: var(--titleLevel2);
    line-height: 1.4;

    ${media.tablet`
      font-size: var(--titleLevel2);
    `}

  }
`
export const ImgContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-image: ${({ highlightColor }) =>
      (highlightColor === 'purple' && 'var(--purpleGrad)') ||
      (highlightColor === 'blue' && 'var(--blueGrad)') ||
      (highlightColor === 'green' && 'var(--greenGrad)') ||
      (highlightColor === 'orange' && 'var(--orangeGrad)') ||
      'var(--greyGrad)'
    };
    opacity: .75;
  }

  > div {
    height: 100%;
  }
`
