import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Button from 'components/button'
import { Section, ContainerStacked, ContainerSplit, ListItem } from './timeline.css'

const Timeline = ({ timeline }) => (
  <div>
    <ul>
      <Fade bottom delay={150} duration={1250} distance="30px">
        {timeline.steps.map((step, i) => (
          <ListItem key={i}>
            <span>{step.order}</span>
            <div>
              <h3>{step.heading}</h3>
              <p dangerouslySetInnerHTML={{ __html: step.copy }} />
            </div>
          </ListItem>
        ))}
      </Fade>
    </ul>
    <Fade bottom delay={150} duration={1250} distance="30px">
      <Button to={timeline.link}>{timeline.cta}</Button>
    </Fade>
  </div>
)

export const TimelineStacked = ({ timeline }) => (
  <Section>
    <ContainerStacked>
      <Fade bottom delay={150} duration={1250} distance="30px">
        <h3>{timeline.heading}</h3>
      </Fade>
      <Timeline timeline={timeline} />
    </ContainerStacked>
  </Section>
)

export const TimelineSplit = ({ timeline }) => (
  <Section>
    <ContainerSplit>
      <Fade bottom delay={150} duration={1250} distance="30px">
        <h3>{timeline.heading}</h3>
      </Fade>
      <Timeline timeline={timeline} />
    </ContainerSplit>
  </Section>
)

Timeline.propTypes = {
  timeline: PropTypes.object.isRequired
}
