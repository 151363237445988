import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Section, Container, ImgContainer } from './heroBanner.css'

const HeroBanner = ({ hero, highlightColor }) => {
  const image = getImage(hero.image)
  return (
    <Section>
      <Container>
        <Fade delay={100} duration={1500}>
          <h1>{hero.heading}</h1>
        </Fade>
      </Container>

      <ImgContainer highlightColor={highlightColor}>
        <GatsbyImage image={image} alt={hero.heading} />
      </ImgContainer>
    </Section>
  )
}

HeroBanner.propTypes = {
  hero: PropTypes.object.isRequired,
  highlightColor: PropTypes.string
}

export default HeroBanner
